<template>
  <div class="side-nav">
    <Col :span="24">
      <Menu
        :default-active="defaultActiveValue"
        class="el-menu-vertical-demo"
        unique-opened
        @open="handleOpen"
        @close="handleClose"
      >
        <Submenu index="1">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span slot="title">页面设置</span>
          </template>
          <MenuItem
            index="1-1"
            activeIndex="1-1"
            @click="skipTo"
            url="/admin/index"
            >首页</MenuItem
          >
          <MenuItem
            index="1-2"
            activeIndex="1-2"
            @click="skipTo"
            url="/admin/welfare"
            >琪志公益</MenuItem
          >
          <MenuItem
            index="1-3"
            activeIndex="1-3"
            @click="skipTo"
            url="/admin/respon"
            >品牌责任</MenuItem
          >
          <MenuItem
            index="1-4"
            activeIndex="1-4"
            @click="skipTo"
            url="/admin/original"
            >品牌初心</MenuItem
          >
        </Submenu>
        <MenuItem index="2" activeIndex="2" @click="skipTo" url="/admin/news">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">新闻发布管理</span>
        </MenuItem>
        <Submenu index="3">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span slot="title">库存管理</span>
          </template>
          <MenuItem
            index="3-1"
            activeIndex="3-1"
            @click="skipTo"
            url="/admin/inventory"
          >
            数据字典
          </MenuItem>
          <MenuItem
            index="3-2"
            activeIndex="3-2"
            @click="skipTo"
            url="/admin/inventory/index"
            >库存列表</MenuItem
          >
        </Submenu>
      </Menu>
    </Col>
  </div>
</template>
<script>
import { Col, Menu, MenuItem, Submenu } from "element-ui";
export default {
  name: "SideNav",
  components: {
    Col,
    Menu,
    MenuItem,
    Submenu,
  },
  data() {
    return {
      defaultActiveValue: "1-1",
    };
  },
  methods: {
    skipTo(e) {
      if (e.$attrs?.url && this.$route.path == e.$attrs?.url) {
        return;
      }
      this.defaultActive = e.$attrs?.index;
      this.$router.push({
        path: e.$attrs?.url,
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="scss">
.side-nav {
  width: 220px;
  min-height: 100vh;
  border-right: solid 1px #e6e6e6;
  position: fixed;
  .el-menu {
    border-right: none;
  }
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    background: #fff;
  }
  .el-menu-item.is-active,
  .el-menu-item:focus,
  .el-menu-item:hover {
    color: #fff;
    background: rgb(157 30 30);
  }
  .el-menu-item {
    border-bottom: 1px solid #fff;
  }
}
</style>
