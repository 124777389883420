<template>
  <div class="index-header">
    <div class="header">
      <div class="header-con clearfix">
        <div class="ed-logo">
          <a href="/">
            <img :src="qzLogo" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      qzLogo:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/logo@2x.webp",
      isMenuOpen: false,
    };
  },
  methods: {
    openMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style lang="scss">
.header .header-con {
  padding: 15px 62px 15px 32px;
}
</style>