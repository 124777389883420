<template>
  <div class="qz-home">
    <div class="pageCon">
      <admin-header></admin-header>
      <div class="home-content">
        <side-nav></side-nav>
        <div class="home-main">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import AdminHeader from "./Headers.vue";
import SideNav from "./SideNav.vue";
export default {
  name: "Layout",
  components: {
    AdminHeader,
    SideNav,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
<style lang="scss">
.qz-service-win {
  .msg-btn {
    width: 34px;
    height: 34px;
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 10;
    &:hover {
      cursor: pointer;
    }
  }
}
.home-content {
  margin-top: 67px;
  display: flex;
  flex-direction: row;
  width: 100%;
  .home-main {
    margin: 15px 15px 15px 235px;
    width: calc(100% - 250px);
  }
}
</style>